import React, {useEffect, useState} from 'react';
import './App.scss';
import {Route} from 'react-router-dom'
import {connect} from 'react-redux'

import {getApiBase} from './../helpers/helpers'

import Page from './Page'
import Frontpage from './Frontpage'
import Navigation from './Navigation'
import Loading from './Loading'
import AnimatedScrollHandler from './AnimatedScrollHandler'


function App(props) {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {

		const fetchDataFooter = async () => {
			const response = await fetch(getApiBase()+'/wp-json/wp/v2/acf/options');
			const jsonResponse = await response.json();
			props.dispatch({
				type: 'SET_APP_DATA',
				data: jsonResponse
			})
			setIsLoading(false)
		}
		fetchDataFooter();
	}, [])

	return (
	<div className="App">
	{isLoading ? <Loading/> : <Navigation/>}
	  
	  <Route path="/" component={Frontpage} exact/>
	  <Route path="/:id" component={Page}/>
      <AnimatedScrollHandler/>
	</div>
	);
}

export default connect()(App);