import React, {useState} from 'react';
import './index.scss';
import marker from './../../../assets/icons/marker.png'
import {NavLink, Link} from 'react-router-dom'
import {connect} from 'react-redux'

import {ReactComponent as IconBurger} from './../../../assets/icons/icon-burger.svg'

function Navigation(props) {
	const [showMenu, setShowMenu] = useState(false)

  return (
  	<div id="navigation" className={`row color-bg-${props.color}`}>
  		<div className="row-inner">
	  		<Link to='/' className="icon-marker" onClick={() => setShowMenu(false)}><img src={marker}/></Link>
	  		<IconBurger className="menu-toggle" onClick={() => setShowMenu(!showMenu)}/>
		    <ul className="menuitems desktop">
		    	{props.menuItems ? props.menuItems.map((ele, i) => (
			    		<li key={i}>
			    			<NavLink to={ele.post_name}><span>{ele.post_title}</span></NavLink>
						</li>
		    		)) : null}
		    	<li key="10000">
	    			<a href="#footer"><span>Kontakt</span></a>
				</li>
		    </ul>
		    <div className={`mobile-menu-wrapper color-bg-${props.color} ${showMenu ? 'show' : 'hide'}`}>
		    	<ul className="menuitems">
		    		{props.menuItems ? props.menuItems.map((ele, i) => (
			    		<li key={i}>
			    			<NavLink to={ele.post_name} onClick={() => setShowMenu(false)}><span>{ele.post_title}</span></NavLink>
						</li>
		    		)) : null}
			    	<li key="10000">
		    			<a href="#footer" onClick={() => setShowMenu(false)}><span>Kontakt</span></a>
					</li>
			    </ul>
		    </div>
	   	</div>
   	</div>
  );
}

export default connect((state) => ({
	color: state.App.color,
	menuItems: state.App.appData.menuitems
}))(Navigation);
