import React, {useEffect, useState} from 'react';
import './index.scss';

import {getApiBase, parseHTML} from './../../helpers/helpers'
import { Header, Footer, Document, Packer, Paragraph, TextRun, Media, HorizontalPositionRelativeFrom, HorizontalPositionAlign, VerticalPositionRelativeFrom, VerticalPositionAlign } from 'docx'
import {saveAs} from 'file-saver'
import {connect} from 'react-redux'

import {useParams,} from 'react-router-dom'

import QuestionSingle from './../QuestionSingle'
import PageContent from './../PageContent'
import PageHeader from './../PageHeader'
import PageFooter from './../PageFooter'
import Loading from './../Loading'

import markerImg from './../../../assets/icons/marker.png'

import {animateScroll} from './../../actions';

const breakPoint = 750;

const Navigation = (props) => {
	const [shouldBreak, setShouldBreak] = useState(false)
	const topOffset = 160, topOffsetMob = 120;
	const [subMenusIsOpen, setSubmenuIsOpen] = useState(false)

	useEffect(() => {
		setShouldBreak(props.screenWidth <= breakPoint);
	}, [props.screenWidth])

	const linkClickHandler = (e) => {
		e.preventDefault();
		const targetId = e.target.getAttribute('href').substring(1,99);
		animateToId(targetId);
	}

	const animateToId = (id) => {
		const ele = document.getElementById(id);
		const scrollDest = ele.getBoundingClientRect().top + window.pageYOffset - (window.innerWidth <= 750 ? topOffsetMob : topOffset);
		animateScroll(scrollDest);
	}

	// fixes IE11 fixed navbar on scroll
		useEffect(() => {
			var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
			if (isIE11) {
				const pageSections = document.getElementsByClassName('pagesections')[0];
				if (pageSections) {
					const pageNavPos = pageSections.getBoundingClientRect().top

					props.parentProps.dispatch({
						type: 'SET_PAGENAV_TO_STICKY',
						val: pageNavPos <= 160 ? true : false
					})
				}
			}
		}, [props.parentProps.scrollPos])

	return (
		<div className={`pagenav ${subMenusIsOpen ? `open` : ''}`}>
			{props.sections ? props.sections.map((ele, i) => (
				<div className="section" key={i}>
					<div className="title"><a href={`#${ele.overskrift.replace(/ /g, '').toLowerCase()}`} onClick={(e) => linkClickHandler(e)}>{ele.overskrift}</a></div>
					<ul>
		    			{ele.undersektioner.map((eleSub, iSub) => (
			    			<li key={iSub}>
	    						<a href={`#${ele.overskrift.replace(/ /g, '').toLowerCase() + '-' + eleSub.overskrift.replace(/ /g, '').toLowerCase()}`} onClick={(e) => linkClickHandler(e)}>{eleSub.overskrift}</a>
			    			</li>
		    			))}
		    		</ul>
				</div>
			)) : null}
			<div className="toggle" onClick={() => setSubmenuIsOpen(!subMenusIsOpen)}>{!subMenusIsOpen ? `•••` : `✖`}</div>
		</div>
	)
}

const Sections = ({sections}) => (
	<div className="pagesections">
		{sections ? sections.map((ele, i) => (
			<div className="section" key={i}>
				<div className="section-header">
					<h2 className="section-title" id={ele.overskrift.replace(/ /g, '').toLowerCase()}>{ele.overskrift}</h2>
					<div className="section-intro" dangerouslySetInnerHTML={{__html: ele.intro}}/>
				</div>
	    		{ele.undersektioner?.map((eleSub, iSub) => (
	    			<div key={iSub} className="subsection" id={ele.overskrift.replace(/ /g, '').toLowerCase() + '-' + eleSub.overskrift.replace(/ /g, '').toLowerCase()}>
						<h3 className="subsection-title">{eleSub.overskrift}</h3>
						<div className="content" dangerouslySetInnerHTML={{__html: parseHTML(eleSub.indhold)}}/>
	    			</div>
    			))}
			</div>
		)) : null}
	</div>
	)

function Page(props) {
	const [apiData, setApiData] = useState();
	const [isLoading, setIsLoading] = useState(true);

	let {id} = useParams();

	useEffect(() => {
		setIsLoading(true)

		const fetchData = async () => {
			const response = await fetch(getApiBase()+'/wp-json/wp/v2/pages?slug='+id);
			const jsonResponse = await response.json();
			setApiData(jsonResponse[0])
			setIsLoading(false)
			props.dispatch({
				type: 'SET_COLOR',
				value: jsonResponse[0].acf.farve
			})
		}
		fetchData();
	}, [id])

  return (
    isLoading ? <Loading/> : 
	    <div className={`Page${props.pageNavIsSticky ? ' sticky' : ''}`}>
	    	<PageHeader color={apiData.acf.farve} title={apiData.title.rendered} description={apiData.acf.manchet}/>
	    	<PageContent>
	    		<Navigation sections={apiData.acf.sektioner} parentProps={props}/>
	    		<Sections sections={apiData.acf.sektioner}/>
	    	</PageContent>
			<PageFooter color={apiData.acf.farve}/>
	    </div>
		    
  );
}

export default connect(state => ({
	scrollPos: state.App.scrollPosition,
	pageNavIsSticky: state.App.pageNavIsSticky
	// hash: state.router.location.hash,
}))(Page);
