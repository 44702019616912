import React, {useEffect, useState} from 'react';
import './index.scss';

import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import marker from './../../../assets/icons/marker.png'

import {getApiBase} from './../../helpers/helpers'

import Loading from './../Loading'
import PageHeader from './../PageHeader'
import PageContent from './../PageContent'
import PageFooter from './../PageFooter'

function Frontpage(props) {
	const [apiData, setApiData] = useState();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(true)

		const fetchData = async () => {
			const response = await fetch(getApiBase()+'/wp-json/wp/v2/acf/options');
			const jsonResponse = await response.json();
			setApiData(jsonResponse)
			setIsLoading(false)
			props.dispatch({
				type: 'SET_COLOR',
				value: jsonResponse.farve
			})
		}
		fetchData();
	}, [])

  return (
    isLoading ? <Loading/> : 
    <div className="frontpage">
	    <PageHeader color={apiData.farve} title={apiData.frontpage_title} description={apiData.frontpage_description}/>
	    <PageContent>
	    	{apiData.frontpage_section ? apiData.frontpage_section.map((ele, id) => (
		    	<div key={id} className="theme-intro">
	    			<h2>{ele.title}</h2>
	    			<div className="text" dangerouslySetInnerHTML={{__html: ele.text}}/>
	    			{ele.readmore ? <Link to={`/`+ele.readmore.post_name}>Læs mere</Link> : null}
			    </div>
	    		)) : null}
	    </PageContent>
	    <PageFooter color={apiData.farve}/>
    </div>
  );
}

export default connect()(Frontpage);