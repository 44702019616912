export function getApiBase() {
	return process.env.NODE_ENV === 'production' ? 'https://rekrutteringgentofte.dk/api' : 
	'https://rekrutteringgentofte.dk/api';
	// 'http://gentofte.troelsknud.dk/api';
	// 'http://udviklingssamtalergentofte.dk/api';
}

export function formatString(str) {
	var theString = str.replace('<p>','').replace('</p>','');
	return theString;
}

export function parseHTML(str) {
	var regexStart = new RegExp('<p><iframe', "g");
	var regexEnd = new RegExp('</iframe></p>', "g");
	var theString = str.replace(regexStart, '<div class="iframe-wrap"><iframe').replace(regexEnd, '</iframe></div>');
	return theString;
}