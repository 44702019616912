const initialState = {
	color: 'white',
	appData: null,
	scrollDest: null,
	scrollIsAnimating: false,
	scrollPosition: 0,
	pageNavIsSticky: false
}

const App = (state = initialState, action) => {
	switch(action.type) {

		case 'SET_COLOR':
			return {
				...state,
				color: action.value,
			}
		break;

		case 'SET_APP_DATA':
			return {
				...state,
				appData: action.data,
			}
		break;

		case 'SET_SCROLL_POSITION':
			return {
				...state,
				scrollPosition: action.scrollPosition
			}
		break;

		case 'SET_SCROLL_DEST':
			return {
				...state,
				scrollDest: action.scrollDest,
				scrollIsAnimating: true,
			}
		break;

		case 'RESET_ANIMATED_SCROLL':
			return {
				...state,
				scrollIsAnimating: false,
				scrollDest: state.scrollPosition,
			}
		break;

		case 'SET_PAGENAV_TO_STICKY':
			return {
				...state,
				pageNavIsSticky: action.val
			}
		break;

		default:
			return state
	}
}

export default App