import React from 'react';
import './index.scss';

import {parseHTML} from './../../helpers/helpers'

function PageHeader(props) {
  return (
	<div className={`row header color-bg-${props.color}`}>
		<div className="row-inner">
			<h1 className="title" dangerouslySetInnerHTML={{__html: props.title}}/>
    		{props.description ? <div className="description" dangerouslySetInnerHTML={{__html: parseHTML(props.description)}}/> : null}
		</div>
	</div>
  );
}

export default PageHeader;
